import React from "react";
import styled from "@emotion/styled";

export default styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;